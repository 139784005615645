import React, { Component } from 'react';
import logo from './baby_shower.png'
import './App.css';
import Rsvp from './rsvp';
function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      <div className="infoContainer">
        <button
          className="btn"
          // onClick={() => this.setState({ isActive: !this.state.isActive })}
          onClick={() => window.open('https://www.eventbrite.com/e/noras-baby-shower-registration-313140621027')}
        >
          Rsvp
        </button>
        <button
          className="btn"
          onClick={() => window.open('https://www.amazon.com/baby-reg/nora-kooinga-june-2022-phoenix/30O629PGU87LQ')}
        >
          Registry
        </button>
      </div>

    </div>
  );
}

// class App extends Component {
//   // state = {
//   //   activeRsvp: false
//   // };

//   render() {
//     return (
//       <div className="App">
//         <header className="App-header">
//         {/* <div className='container'> */}
//           {/* <div className="App-logo"/> */}
//           {/* <Rsvp /> */}
//         {/* </div> */}
//       </header>
//     </div>
//   );
//   }
// }

export default App;
