import React, { Component } from 'react';
import Input from './input'
// import "react-widgets/styles.css";


export default class Rsvp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            personName: '',
            email: '',
            guests: 0,
        }
    }

    handleRsvp() {
        // const {
        //     personName,
        //     email,
        //     guests,
        // } = this.state;

        // this.props.handleRsvp({
        //     name: personName,
        //     email,
        //     guests
        // });
    }

    handleOnChange(e) {
        console.log('handleOnChange e is: ', e);
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        const {
            isActive,
            personName,
            email,
            guests
        } = this.state;

        return (
            <div className='container'>
                {isActive
                ? (<div className="infoContainer">
                    <Input
                        type="text"
                        label="Name"
                        value={personName}
                        id="personName"
                        className="infoContainer field"
                        onChange={() => this.handleOnChange}
                    />
                    <Input
                        type="text"
                        label="Email"
                        value={email}
                        id="email"
                        className="infoContainer field"
                        onChange={() => this.handleOnChange}
                    />
                    <Input
                        type="number"
                        label="Guests"
                        value={guests}
                        id="guests"
                        className="infoContainer field"
                        onChange={() => this.handleOnChange}
                        />
                    <div>
                        <button 
                            className='btn-submit'
                            onClick={() => this.handleRsvp}
                        >
                            Submit
                        </button>
                    </div>
                </div>)
                : (
                    <div className="infoContainer">
                        <button
                            className="btn"
                            // onClick={() => this.setState({ isActive: !this.state.isActive })}
                            onClick={() => window.open('https://www.eventbrite.com/e/noras-baby-shower-registration-313140621027')}
                            >
                            Rsvp
                        </button>
                        <button
                            className="btn"
                            onClick={() => window.open('https://www.amazon.com/baby-reg/nora-kooinga-june-2022-phoenix/30O629PGU87LQ')}
                            >
                            Registry
                        </button>
                    </div>
                )}
            </div>
        )
    }
}